var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { popupClose: "" } }, [
      _c("span", [_vm._v(_vm._s(_vm.closeMsg))])
    ]),
    _c("div", { staticClass: "screen_box" }, [
      _c("img", { attrs: { src: _vm.videoSrc } })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }